import React from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const Footer = () => {

  return (
    <footer className="bg-gray-800 text-white py-10">
      <div className="container mx-auto px-5 flex flex-col items-center">
        <h3 className="text-lg font-bold mb-4">Connect With Me</h3>
        <div className="flex space-x-6 mb-4">
          <a href="https://github.com/Caleb-ne1" target="_blank" rel="noopener noreferrer">
            <FaGithub className="text-2xl hover:text-teal-500 transition-colors duration-200" />
          </a>
          <a href="https://www.linkedin.com/in/caleb-kibet" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="text-2xl hover:text-teal-500 transition-colors duration-200" />
          </a>
        </div>
        <p className="text-center text-sm">
          &copy; {new Date().getFullYear()} Caleb Kibet. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
