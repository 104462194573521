import React from 'react';
import { Link } from 'react-router-dom';
import projects from '../data/Projects.json';

const Projects = () => {
  return (
    <section id="projects" className="py-20 bg-white">
      <div className="container max-w-6xl px-5 mx-auto">
        {/* Section Title */}
        <h2 className="mb-12 text-5xl font-extrabold tracking-wide text-center text-gray-800 animate-fadeIn">
          My Projects
        </h2>

        {/* Project Cards */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {projects.slice(0, 3).map((project, index) => (
            <div
              key={index}
              className="p-6 text-gray-900 transition-transform duration-300 bg-white border border-gray-200 shadow-lg rounded-xl hover:scale-105 hover:shadow-xl"
            >
              <div className="flex flex-col justify-between h-full">
                <div>
                  <div className="flex items-center justify-center w-16 h-16 mb-4 text-white bg-teal-500 rounded-full shadow-lg">
                    <span className="text-2xl font-bold">{project.title.charAt(0)}</span>
                  </div>
                  <h3 className="mb-2 text-2xl font-bold">{project.title}</h3>
                  <p className="mb-4 text-gray-600">{project.description}</p>
                </div>
                <div className="text-center">
                  <Link
                    to={`/project-details/${project.id}`}
                    className="inline-block px-6 py-3 text-lg font-semibold text-gray-800 transition duration-300 border border-gray-300 rounded-full shadow-sm hover:bg-gray-100"
                  >
                    View Project
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* View All Projects Button */}
        <div className="mt-10 text-center">
          <a
            href="/all-projects"
            className="inline-block px-8 py-4 text-xl font-semibold text-gray-800 transition duration-300 border border-gray-300 rounded-full shadow-sm hover:bg-gray-100"
          >
            View All Projects
          </a>
        </div>
      </div>
    </section>
  );
};

export default Projects;


