import React from 'react';
import skills from '../data/Skills.json';

const Skills = () => {
  return (
    <section id="skills" className="py-20 bg-gray-100">
      <div className="container mx-auto px-5 max-w-7xl">
        <h2 className="text-5xl font-bold text-center mb-12 text-gray-800">
          My Skills
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">

          {/* Frontend Skills*/}
          <div className="bg-gradient-to-br from-purple-500 to-green-400 text-white shadow-lg rounded-lg p-6 transition transform duration-300 hover:scale-105 hover:shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Frontend</h3>
            <ul>
              {skills.frontend.map((skill, index) => (
                <li key={index} className="flex items-center mb-3">
                  <img src={skill.icon} alt={skill.name} className="w-6 h-6 mr-3" />
                  <span className="text-lg">{skill.name}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Backend Skills */}
          <div className="bg-white text-gray-800 shadow-lg rounded-lg p-6 transition transform duration-300 hover:scale-105 hover:shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Backend</h3>
            <ul>
              {skills.backend.map((skill, index) => (
                <li key={index} className="flex items-center mb-3">
                  <img src={skill.icon} alt={skill.name} className="w-6 h-6 mr-3" />
                  <span className="text-lg">{skill.name}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Database */}
          <div className="bg-gradient-to-br from-purple-500 to-green-400 text-white shadow-lg rounded-lg p-6 transition transform duration-300 hover:scale-105 hover:shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Database</h3>
            <ul>
              {skills.database.map((skill, index) => (
                <li key={index} className="flex items-center mb-3">
                  <img src={skill.icon} alt={skill.name} className="w-6 h-6 mr-3" />
                  <span className="text-lg">{skill.name}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Other Technologies */}
          <div className="bg-white text-gray-800 shadow-lg rounded-lg p-6 transition transform duration-300 hover:scale-105 hover:shadow-xl">
            <h3 className="text-2xl font-bold mb-4">Other Tech...</h3>
            <ul>
              {skills.otherTech.map((skill, index) => (
                <li key={index} className="flex items-center mb-3">
                  <img src={skill.icon} alt={skill.name} className="w-6 h-6 mr-3" />
                  <span className="text-lg">{skill.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
