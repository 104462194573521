import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="fixed top-0 z-40 w-full p-5 text-gray-900 bg-white shadow-md">
      <div className="container flex items-center justify-between mx-auto">
        <Link to="/">
          <div className="text-2xl font-bold text-gray-900">Caleb Kibet</div>
        </Link>

        {!location.pathname.startsWith("/project-details") &&
          !location.pathname.startsWith("/all-projects") && (
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-gray-900 focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {isOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  )}
                </svg>
              </button>
            </div>
          )}

        {!location.pathname.startsWith("/project-details") &&
          !location.pathname.startsWith("/all-projects") && (
            <ul className="hidden space-x-8 md:flex">
              <li>
                <a
                  href="#home"
                  className="transition duration-300 ease-in-out hover:text-blue-600"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="transition duration-300 ease-in-out hover:text-blue-600"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#skills"
                  className="transition duration-300 ease-in-out hover:text-blue-600"
                >
                  Skills
                </a>
              </li>
              <li>
                <a
                  href="#projects"
                  className="transition duration-300 ease-in-out hover:text-blue-600"
                >
                  Projects
                </a>
              </li>
              <li>
                <a
                  href="#connect"
                  className="transition duration-300 ease-in-out hover:text-blue-600"
                >
                  Contact
                </a>
              </li>
            </ul>
          )}

        <div
          className={`md:hidden absolute top-16 left-0 w-full bg-white text-gray-900 shadow-md transition-transform duration-300 ease-in-out ${
            isOpen ? "transform translate-x-0" : "transform -translate-x-full"
          }`}
        >
          <ul className="flex flex-col items-center py-5 space-y-5">
            <li>
              <a href="#home" className="hover:text-blue-600" onClick={toggleMenu}>
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="hover:text-blue-600" onClick={toggleMenu}>
                About
              </a>
            </li>
            <li>
              <a href="#skills" className="hover:text-blue-600" onClick={toggleMenu}>
                Skills
              </a>
            </li>
            <li>
              <a href="#projects" className="hover:text-blue-600" onClick={toggleMenu}>
                Projects
              </a>
            </li>
            <li>
              <a href="#connect" className="hover:text-blue-600" onClick={toggleMenu}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

