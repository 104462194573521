import React from 'react';
import profile from '../images/profile.jpg';

const Home = () => {
  return (
    <section id="home" className="relative flex items-center justify-center h-screen bg-white">
      <div className="relative z-10 flex flex-col items-center justify-center max-w-5xl p-4 mx-auto space-y-8 md:flex-row md:space-y-0 md:space-x-12">
        {/* Profile Image */}
        <div className="w-48 h-48 transition-transform duration-500 transform md:w-64 md:h-64 hover:scale-105 hover:rotate-2">
          <img
            src={profile}
            alt="Caleb Kibet"
            className="object-cover w-full h-full transition-shadow duration-300 border-4 border-gray-300 rounded-full shadow-lg hover:shadow-gray-400"
          />
        </div>

        {/* Text Content */}
        <div className="text-center text-gray-900 md:text-left">
          <h1 className="text-5xl font-extrabold leading-tight md:text-7xl">
            Hello, I'm Caleb Kibet
          </h1>
          <p className="mt-4 text-lg font-medium text-gray-600 md:text-2xl">
            Full-Stack Developer | Software Engineer
          </p>

          {/* Social Links */}
          <div className="flex justify-center mt-6 space-x-6">
            <a
              href="https://github.com/Caleb-ne1"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-12 h-12 transition duration-300 bg-gray-300 rounded-full shadow-md hover:bg-gray-400"
            >
              <img
                src="https://img.icons8.com/material-outlined/48/000000/github.png"
                alt="GitHub"
                className="w-8 h-8"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/caleb-kibet"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-12 h-12 transition duration-300 bg-gray-300 rounded-full shadow-md hover:bg-gray-400"
            >
              <img
                src="https://img.icons8.com/color/48/000000/linkedin.png"
                alt="LinkedIn"
                className="w-8 h-8"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;

