import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import DetailProjectPage from './components/DetailProjectPage';
import Connect from './components/Connect';
import Footer from './components/Footer';
import AllProjects from './components/AllProjects';
import Skills from './components/Skills';
function App() {
  return (
    <div className="App">
    <Router>
        <Navbar />
        <Routes>
        <Route
            path="/"
            element={
              <>
                <Home id="home" />
                <About  id="about" />
                <Skills id="skills" />
                <Projects id="projects" />
                <Connect id="connect" />
              </>
            }
          />
          <Route path="/project-details/:id" element={ <DetailProjectPage />} />
          <Route path="/all-projects" element={ <AllProjects />} />
        </Routes>
        <Footer />
    </Router>
  </div>
  );
}

export default App;
