import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-20 bg-gradient-to-b from-white to-gray-100">
      <div className="container max-w-4xl px-5 mx-auto text-center">
        <h2 className="mb-6 text-4xl font-bold text-gray-800 animate-fadeIn">
          About Me
        </h2>

        <p className="max-w-2xl mx-auto mb-6 text-lg leading-relaxed text-gray-700">
          Hello! I’m <span className="font-semibold text-teal-600">Caleb Kibet</span>, a passionate full-stack developer eager to kickstart my career in web development.
          With hands-on experience in front-end technologies like React and back-end solutions like Node.js, I love building dynamic and user-friendly applications.
        </p>

        <p className="max-w-2xl mx-auto text-lg leading-relaxed text-gray-700">
          I am committed to writing clean and efficient code, thriving in collaborative environments, and constantly improving my skills. I am excited to contribute to projects that bring value to the tech industry!
        </p>
      </div>
    </section>
  );
};

export default About;

