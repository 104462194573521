import React from 'react';
import projects from '../data/Projects.json';

const AllProjects = () => {
  return (
    <div className="min-h-screen py-20 bg-white">
      <div className="container max-w-6xl px-5 mx-auto">
        {/* Section Title */}
        <h1 className="mb-12 text-5xl font-extrabold text-center text-gray-900">
          All Projects
        </h1>

        {/* Projects Grid */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {projects.map((project, index) => (
            <div
              key={index}
              className="p-6 text-gray-900 transition duration-300 transform bg-white border border-gray-300 shadow-lg rounded-xl hover:scale-105 hover:shadow-xl"
            >
              <div className="flex flex-col justify-between h-full">
                <h3 className="mb-2 text-2xl font-bold text-gray-800">
                  {project.title}
                </h3>
                <p className="mb-4 text-gray-600">{project.description}</p>

                <div className="text-center">
                  <a
                    href={`/project-details/${project.id}`}
                    className="inline-block px-6 py-3 text-lg font-semibold text-white transition duration-300 bg-gray-800 rounded-full shadow-md hover:bg-gray-900"
                  >
                    View Project
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllProjects;

