import React, { useState } from 'react';

const Connect = () => {
  const [formData, setFormData] = useState({ name: '', email: '', subject: '', message: '' });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeoutReached, setTimeoutReached] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
        setError('All fields are required!');
        return;
    }

    setLoading(true); 
    setTimeoutReached(false);

    fetch('https://sendmail.calebkibet.online/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error('Failed to send message');
        }
        return response.json();
    })
    .then(() => {
        setSubmitted(true);
        setFormData({ name: '', email: '', subject: '', message: '' }); 
        setError('');
    })
    .catch(() => {
        setError('Failed to send message. Please try again.');
    })
    .finally(() => {
        setLoading(false);
        setTimeout(() => setTimeoutReached(true), 4000);
    });
  };

  return (
    <section id="connect" className="py-20 bg-white">
      <div className="container px-5 mx-auto">
        {/* Section Title */}
        <h2 className="mb-8 text-4xl font-bold text-center text-gray-800">Contact Me</h2>

        {/* Contact Form */}
        <form
          className="max-w-lg p-8 mx-auto transition-transform transform bg-white border border-gray-200 rounded-lg shadow-lg hover:scale-105"
          onSubmit={handleSubmit}
        >
          {/* Success & Error Messages */}
          {submitted && !timeoutReached && (
            <p className="mb-4 font-semibold text-center text-green-600">Message sent successfully!</p>
          )}
          {error && (
            <p className="mb-4 font-semibold text-center text-red-600">{error}</p>
          )}

          {/* Name Field */}
          <div className="mb-6">
            <label className="block mb-2 text-gray-700" htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              className="w-full px-4 py-3 transition duration-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
              value={formData.name}
              onChange={handleChange}
              required
              aria-label="Name"
            />
          </div>

          {/* Email Field */}
          <div className="mb-6">
            <label className="block mb-2 text-gray-700" htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              className="w-full px-4 py-3 transition duration-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
              value={formData.email}
              onChange={handleChange}
              required
              aria-label="Email"
            />
          </div>

          {/* Subject Field */}
          <div className="mb-6">
            <label className="block mb-2 text-gray-700" htmlFor="subject">Subject</label>
            <input
              type="text"
              name="subject"
              id="subject"
              className="w-full px-4 py-3 transition duration-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
              value={formData.subject}
              onChange={handleChange}
              required
              aria-label="Subject"
            />
          </div>

          {/* Message Field */}
          <div className="mb-6">
            <label className="block mb-2 text-gray-700" htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              rows="4"
              className="w-full px-4 py-3 transition duration-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
              value={formData.message}
              onChange={handleChange}
              required
              aria-label="Message"
            ></textarea>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className={`${loading ? 'bg-gray-400' : 'bg-gray-800 hover:bg-gray-900'} w-full text-white px-4 py-3 rounded-lg transition duration-300 font-semibold`}
            disabled={loading}
          >
            {loading ? "Sending message..." : "Send Message"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Connect;

