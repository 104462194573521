import React from "react";
import { IoLogoHtml5 } from "react-icons/io5";
import { SiTailwindcss } from "react-icons/si";
import { FaCss3Alt } from "react-icons/fa";
import { FaGithub, FaLink, FaReact } from "react-icons/fa";
import { useParams } from "react-router-dom";

import projects from "../data/Projects.json";

const DetailProjectPage = () => {
  const { id } = useParams();
  const project = projects.find((project) => project.id === id);

  if (!project) {
    return (
      <div className="py-20 text-center">
        <h2 className="text-3xl font-bold text-red-600">Project Not Found</h2>
      </div>
    );
  }

  const iconMapping = {
    IoLogoHtml5: IoLogoHtml5,
    FaReact: FaReact,
    FaCss3Alt: FaCss3Alt,
    SiTailwindcss: SiTailwindcss,
  };

  return (
    <section className="min-h-screen py-20 bg-gray-50">
      <div className="container max-w-6xl px-5 mx-auto">
        {/* Project Title */}
        <h2 className="mb-8 text-5xl font-bold text-center text-gray-900">
          {project.title}
        </h2>
        <p className="mb-6 text-lg text-center text-gray-700">
          {project.description}
        </p>

        {/* Buttons: GitHub Repo & Live Demo */}
        <div className="flex justify-center mb-8">
          <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-6 py-3 mr-4 text-white transition duration-300 transform bg-gray-800 rounded-lg shadow-md hover:bg-gray-900 hover:scale-105"
          >
            <FaGithub className="mr-2" />
            GitHub Repo
          </a>
          <a
            href={project.demo}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-6 py-3 text-white transition duration-300 transform bg-teal-500 rounded-lg shadow-md hover:bg-teal-600 hover:scale-105"
          >
            <FaLink className="mr-2" />
            Live Demo
          </a>
        </div>

        {/* Technologies Used */}
        <h3 className="mb-6 text-4xl font-semibold text-gray-900">
          Technologies Used
        </h3>
        <div className="flex flex-wrap gap-4">
          {project.technologies &&
            project.technologies.map((tech, index) => {
              const IconComponent = iconMapping[tech.icon];
              return (
                <div
                  key={index}
                  className="flex items-center gap-2 px-5 py-3 text-white transition duration-300 transform rounded-full shadow-md bg-gradient-to-r from-gray-800 to-gray-600 hover:shadow-lg hover:scale-105"
                >
                  {IconComponent && (
                    <IconComponent className="text-2xl text-teal-400" />
                  )}
                  <span className="text-lg font-semibold">{tech.name}</span>
                </div>
              );
            })}
        </div>

        {/* Images Section */}
        <h3 className="mb-6 text-4xl font-semibold text-gray-900 mt-7">
          Project Images
        </h3>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {project.images &&
            Array.isArray(project.images) &&
            project.images.map((image, index) => (
              <div
                key={index}
                className="overflow-hidden transition-transform duration-300 border border-gray-300 rounded-lg shadow-md hover:shadow-lg hover:scale-105"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/${image}`}
                  alt={`Project image ${index + 1}`}
                  className="object-cover w-full h-auto rounded-lg"
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default DetailProjectPage;
